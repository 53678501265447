<template>
  <div class="d-block ">
<div class="row">
  <div class="col-9  col-sm-9 col-lg-10  fs-4  page-heading-parter ">
    <p class="mypracticeMobile pt-3" style="margin-left: 20px;">Inventory</p>
  </div>
  <div class="col-2 col-sm-3 col-lg-2">
      <div class="my-3 text-center">
  <button  class="btn btn-blue-color text-white" style="border-radius: 10px;" @click="GoSettingBack()" >Back</button>
</div>
  </div>
</div>
<div class="hr-border" ></div>
</div>



<div class="px-4 mt-4 row">
<div class=" col-lg-1 col-3 fs-5 ">
  <label class="inventory_pointer"  @click="enableModels()" :class="{ active: isModelActive }">Models</label>
</div>
<div class="fs-5  col-lg-2 col-5">
  <label class="inventory_pointer" @click="enableUpperColour()" :class="{ active: isUpperColourActive }">Upper colours</label>
</div>

</div>


<div class="" v-if="openModels">

  <div class="px-4" v-if="!inventoryData.length"> 
<div class="mt-3 pb-2"  style="margin-top:10px"  v-if="noModel && inventoryData.length === 0">
    <h5> There are no items</h5>
    </div>
</div>
<div class="px-4 mt-3" v-else>
<div class="row ">
<div>
  <label>Model number</label>
</div>
    <div class="col-1">
    </div>
    
  </div>
    <div class="row mt-2" v-for="(value, index) in inventoryData" :key="index">
      
      <div class="col-9 col-lg-4">
        <input type="text"  :disabled="!value.isEditable" :value="value.model_no"  autocomplete="off" class="form-control" />
      </div>
      
      <div class="col-3 col-lg-4">
        <button type="button" @click="handleInputEditState(value, index)" style="font-size: 20px" id="btn_schedule_add"
          class="btn btn_edit">
          <font-awesome-icon :icon="['fas', 'edit']" />
        </button>
      </div>
    
    </div>
</div>

<div class="mt-2 ms-4" v-if="addNewModelButton">
<button  class="btn btn-blue-color text-white" style="border-radius: 10px;" @click="enableClinic()" >Add new model</button>
</div>
<div class="mobileStyleDoctor" v-if="newModel">

<div class="d-flex justify-content-end">
  <button type="button" @click="cancelModel(value, index)" style="font-size: 10px" id="btn_schedule_add"
        class="btn btn_edit fs-3">
        <font-awesome-icon :icon="['fas', 'window-close']" />
      </button>
</div>
<div class="row clinicTextAlign ms-2" >
  <div class="clinic_section text-align text-left"  style="margin: 20px 0px">
      <fieldset class="curve-box" style="margin: 0px;padding: 0px 15px 15px 15px">
          <legend class="subHeadingText">Model info</legend> 
          <div class="row">
          <div class="col-12 col-lg-4">
            <div class="form-group my-2">
              <label class="receiveConsultation">Model number<span class="star">*</span> </label>
              <input type="text" v-model="this.model_no" class="form-control" name="model_no" id="model_no"  
                />
            </div>
          </div>
        </div>
        
        <div class="row my-2">
          <div class="col-12 col-lg-4">
<label>Colour</label>
<select class="form-control consultation-select" v-model="newColour" @change="handleColourSelection">
  <option value="">Select colour</option>
  <option v-for="(item, index) in colourDataInventory" :key="index" :value="item.id">
    {{ item.colour_name }}
  </option>
</select>
<div class="selected-colours mt-2">
  <span v-for="(colour, index) in selectedColours" :key="index" class="mx-1 fs-5 text-white  p-1 ps-3  bg-color">
    {{ getColourNameById(colour) }} 
    <button @click="removeColour(index)" class="btn fs-5 ml-1">x</button>
  </span>
</div>
</div>

      </div>

      <!-- <div class="row mt-3">
  <label>Upload Images</label>
  <div class="col-10" v-for="n in 6" :key="n">
    <div class="form-group my-2">
      <label class="form-control cursor UpdateTextAlignment" style="border-radius: 10px; padding: 10px; display:flex; position: relative;">
        <input
          @change="uploadImage($event, n)"
          accept="image/*"
          type="file"
          class="form-control cursor UpdateTextAlignment"
          style="border-radius: 10px; opacity: 0; position: absolute; width: 100%; height: 100%; top: 0; left: 0; z-index: 2;"
        />
        <input
          type="text"
          class="form-control cursor UpdateTextAlignment"
          :value="imageNames[n-1]  ? imageNames[n-1] :formattedImageNames[n-1]  ? formattedImageNames[n-1]  :'No file chosen'"
          readonly
          style="border-radius: 10px; pointer-events: none; margin-right: 10px; z-index: 1;"
        />
      </label>
      <div v-if="uploadStatus[n - 1]" style="color: green; margin-top: 5px;">
        Uploaded
      </div>
    </div>
  </div>
</div> -->


<div class="row mt-3">
    <label>Upload Images</label>
    <div class="col-lg-12 ">
      <div class="form-group my-2">
        <div
          class="image-upload-container " 
          @click="triggerFileInput"
          style="position: relative; display: flex; justify-content: start; align-items: center; cursor: pointer;"
        >
          <input
            ref="fileInput"
            @change="uploadImage($event)"
            accept="image/*"
            type="file"
            class="form-control"
            style="display: none;"
            multiple
          />
          <div
            class="add-icon bg-color"
            style="width: 60px; height: 60px; border-radius: 50%; background-color: #007bff; display: flex; justify-content: center; align-items: center; color: white; font-size: 24px;"
          >
          <font-awesome-icon :icon="['fas', 'upload']" />
          </div>
        </div>
        <div class="uploaded-images  mt-2">
          <div v-for="(image, index) in imagesPreview" :key="index" class="uploaded-image ">
            <img :src="image" alt="Uploaded Image" class="image-thumbnail border" />
            <button @click="removeImage(index)" class="remove-icon" title="Remove Image">
              &times;
            </button>
          </div>
        </div>
       
      </div>
    </div>
  </div>
<div class="row mt-3">
<div class="col-8">
  <div class="form-check form-check-inline">
    <input class="form-check-input" type="checkbox" name="active" id="active" v-model="isActive" />
    <label class="form-check-label" for="activateORdeactivate">Active</label>
  </div>
</div>
</div>

<!-- <div class="row mt-3" v-if="imagesPreview.length">
<div class="col-12">
  <label class="form-check-label" for="activateORdeactivate">Preview</label>
  <div id="carouselExampleControls" class="carousel slide" data-bs-ride="carousel">
    <div class="carousel-inner text-align text-center">
      <div v-for="(image, index) in imagesPreview" :key="index" :class="['carousel-item', { active: index === 0 }]">
        <img :src="image" class="img-carousel-public">
      </div>
    </div>
    <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Previous</span>
    </button>
    <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Next</span>
    </button>
  </div>
</div>
</div> -->

      </fieldset>
      <div class="clinicSubmitBtn my-3" >
  <div style="margin-right: 10px;">
      <button class="btn btn-blue-color text-white" @click="editbutton? updateModel(): createModel()">  {{ this.editbutton ? "Update" : "Submit" }}</button>
  </div>
  <div style="margin-left: 10px;">
       <button class="btn btn-color text-white " @click="GoSetting()">Cancel</button>
  </div>
  </div>
    </div>
  </div>
</div>

</div>

<div class="" v-if="openUpperColour">

<div class="px-4" v-if="!colourData.length"> 
<div class="mt-3 pb-2"  style="margin-top:10px"  v-if="noModel && colourData.length === 0">
  <h5> There are no items</h5>
  </div>
</div>
<div class="px-4 mt-3" v-else>
<div class="row ">
<div>
<label>Colour name</label>
</div>
  <div class="col-1">
  </div>
  
</div>
<div class="row mt-2" v-for="(value, index) in colourData" :key="index">
  
  <div class="col-9 col-lg-4">
    <input type="text"  :disabled="!value.isEditable" :value="value.colour_name"  autocomplete="off" class="form-control" />
  </div>
  
   <div class="col-3 col-lg-4">
    <button type="button" @click="handleInputColourEditState(value, index)" style="font-size: 20px" id="btn_schedule_add"
      class="btn btn_edit">
      <font-awesome-icon :icon="['fas', 'edit']" />
    </button>
  </div>
 
</div>
</div>

<div class="mt-2 ms-4" v-if="addNewColorButton">
<button  class="btn btn-blue-color text-white" style="border-radius: 10px;" @click="enableClinic()" >Add new colour</button>
</div>
<div class="mobileStyleDoctor" v-if="newModel">

<div class="d-flex justify-content-end">
<button type="button" @click="cancelModel(value, index)" style="font-size: 10px" id="btn_schedule_add"
      class="btn btn_edit fs-3">
      <font-awesome-icon :icon="['fas', 'window-close']" />
    </button>
</div>
<div class="row clinicTextAlign" >
<div class="clinic_section text-align text-left"  style="margin: 20px 0px">
    <fieldset class="curve-box" style="margin: 0px;padding: 0px 15px 15px 15px">
        <legend class="subHeadingText">Colour</legend> 
        <div class="row">
        <div class="col-12">
          <div class="form-group my-2">
            <label class="receiveConsultation">Name<span class="star">*</span> </label>
            <input type="text" v-model="this.colour_name" class="form-control" name="colour_name" id="colour_name"  
              />
          </div>
        </div>
      </div>
  

      <div class="row mt-3">
    <label>Upload Images</label>
    <div class="col-lg-12 ">
      <div class="form-group my-2">
        <div
          class="image-upload-container " 
          @click="triggerFileInputColour"
          style="position: relative; display: flex; justify-content: start; align-items: center; cursor: pointer;"
        >
          <input
            ref="fileInput"
            @change="uploadColourImage($event)"
            accept="image/*"
            type="file"
            class="form-control"
            style="display: none;"
            multiple
          />
          <div
            class="add-icon bg-color"
            style="width: 60px; height: 60px; border-radius: 50%; background-color: #007bff; display: flex; justify-content: center; align-items: center; color: white; font-size: 24px;"
          >
          <font-awesome-icon :icon="['fas', 'upload']" />
          </div>
        </div>
        <div class="uploaded-images  mt-2">
          <div v-for="(image, index) in imagesPreview" :key="index" class="uploaded-image ">
            <img :src="image" alt="Uploaded Image" class="image-thumbnail border" />
            <button @click="removeImage(index)" class="remove-icon" title="Remove Image">
              &times;
            </button>
          </div>
        </div>
       
      </div>
    </div>
  </div>


<div class="row mt-3">
<div class="col-8">
<div class="form-check form-check-inline">
  <input class="form-check-input" type="checkbox" name="active" id="active" v-model="isActive" />
  <label class="form-check-label" for="activateORdeactivate">Active</label>
</div>
</div>
</div>



    </fieldset>
    <div class="clinicSubmitBtn my-3" >
<div style="margin-right: 10px;">
    <button class="btn btn-blue-color text-white" @click="editbutton? updateColourModel(): createColourModel()">  {{ this.editbutton ? "Update" : "Submit" }}</button>
</div>
<div style="margin-left: 10px;">
     <button class="btn btn-color text-white " @click="GoSetting()">Cancel</button>
</div>
</div>
  </div>
</div>
</div>

</div>










</template>
<script>
import axios from "axios";
export default {
props: ["docData"],
data() {
return {
  noModel:true,
  maxImages: 6,
  maxColourImages: 1,

  newColour: '', 
  selectedColours: [],
  colourDataInventory:[],
  isColourActive:true,
  images: [], // To store image file names
  imagesPreview: [],
  imageFileNames: [], 
  imageNames:[],
  updateModelData:null,
  updateColourData:null,
  inventoryData:[],
  currentImageName:"",
  colourData:[],
  formattedImageNames: '',
  formattedColourImageNames: '',
  isModelActive: true,
  isUpperColourActive: false,
  openModels:true,
  openUpperColour:false,
  newModel:false,
  addNewModelButton:true,
  addNewColorButton: true,
  model_no:'',
  colour_name:'',
  isActive:false,
  uploadStatus: [] ,
  doctorData: {},
  userSelected:'',
  editbutton: false,
  mobileValidation:/^[6-9]\d{9}$/,
};
},


methods: {

  triggerFileInput() {
      if (this.images.length < this.maxImages) {
        this.$refs.fileInput.click();
      } else {
        this.$swal("You can only upload up to 6 images!");
      }
    },

    triggerFileInputColour() {
      if (this.images.length < this.maxColourImages) {
        this.$refs.fileInput.click();
      } else {
        this.$swal("You can only upload up to 1 images!");
      }
    },
    uploadImage(event) {
      const files = Array.from(event.target.files);
      if (files.length + this.images.length > this.maxImages) {
        this.$swal("You can only upload up to 6 images!");
        return;
      }

      files.forEach((file) => {
        if (file && file.type.startsWith('image/')) {
          const reader = new FileReader();
          reader.onload = (e) => {
            this.imagesPreview.push(e.target.result);
            this.images.push(e.target.result);
            this.uploadStatus = true;
          };
          reader.readAsDataURL(file);
        } else {
          this.$swal("Please select a valid image file!");
        }
      });
    },

    uploadColourImage(event) {
      const files = Array.from(event.target.files);
      if (files.length + this.images.length > this.maxColourImages) {
        this.$swal("You can only upload up to 1 images!");
        return;
      }

      files.forEach((file) => {
        if (file && file.type.startsWith('image/')) {
          const reader = new FileReader();
          reader.onload = (e) => {
            this.imagesPreview.push(e.target.result);
            this.images.push(e.target.result);
            this.uploadStatus = true;
          };
          reader.readAsDataURL(file);
        } else {
          this.$swal("Please select a valid image file!");
        }
      });
    },
    removeImage(index) {
      this.imagesPreview.splice(index, 1);
      this.images.splice(index, 1);
      if (this.images.length === 0) {
        this.uploadStatus = false; 
      }
    },


handleColourSelection() {
  if (this.newColour && !this.selectedColours.includes(this.newColour)) {
    this.selectedColours.push(this.newColour);
  }
  this.newColour = '';
},

removeColour(index) {
  this.selectedColours.splice(index, 1);
},

getColourNameById(colourId) {
  const selectedColour = this.colourDataInventory.find(item => item.id === colourId );
  return selectedColour ? selectedColour.colour_name :!this.isColourActive;
},
enableModels(){
  this.getInventory();
  this.getInventoryColor();
  this.isModelActive = !this.isModelActive;
  this.newModel = false;
  this.addNewModelButton=true
  this.addNewColorButton=true
  this.openModels=true
  this.openUpperColour =false
  this.isUpperColourActive = false;
},
enableUpperColour(){
  this.getInventory();
  this.getInventoryColor();
  this.isModelActive = false
  this.newModel = false;
  this.addNewModelButton=true
  this.addNewColorButton=true


  this.isUpperColourActive = !this.isUpperColourActive;
  this.openModels=false

  this.openUpperColour =true

},
  enableClinic(){
    this.noModel=false
    this.addNewModelButton=false
    this.addNewColorButton =false
     this.newModel = true;
     this.editbutton = false; 
     this.clearDatas();
  },

  cancelModel(index){
    this.newModel = false
    this.addNewModelButton=true
    this.addNewColorButton=true

    this.inventoryData.forEach((customer, i) => {
      customer.isEditable = i === index; })

      this.colourData.forEach((customer, i) => {
        customer.isEditable = i === index; })

  },
  clearDatas(){
this.model_no = '',
this.colour_name='',
this.userSelected = ''
this.selectedColours = [];
this.isActive = false,
this.formattedImageNames =""
this.images = [];
this.imagesPreview = [];
    this.currentImageName = ''; 
    this.formattedColourImageNames = [];
  },
GoSetting() {
  this.newModel = false
  this.addNewModelButton=true
  this.addNewColorButton=true

  window.scrollTo(0, 0);
  this.$router.push(`/doctor/manage/inventory`);
  this.getInventory();
  this.getInventoryColor();
},
GoSettingBack() {
  window.scrollTo(0, 0);
  this.$router.push(`/doctor/manage`);
},

handleInputEditState(value, index) {

  console.log(value,"valuevaluevaluevalue")

  this.inventoryData.forEach((customer, i) => {
    customer.isEditable = i === index; 
  });
  this.addNewModelButton = false;
  this.updateModelData = value;
  this.editbutton = true;
  this.newModel = true;
  this.model_no = value.model_no;
  this.isActive = value.isActive;

  if (value.images && value.images.length > 0) {
    this.images = value.images;
    this.imagesPreview = value.images.map((imageName) => imageName); 
    this.imageFileNames = value.images.map((imageName) => imageName);
    this.imageNames = value.imageNames;
  } else {
    this.images = [];
    this.imagesPreview = [];
    this.imageFileNames = [];
    this.imageNames = [];
  }

  if (value.colour_id && Array.isArray(value.colour_id)) {
    const filteredColours = this.colourData.filter((data) => 
  value.colour_id.includes(String(data.id)) && data.isActive === true
);

    this.selectedColours = filteredColours.map((data) => data.id  ); 

  } else {
    this.selectedColours = [];
  }

  let previousData = [...this.inventoryData];
  previousData[index].isEditable = true;
  this.inventoryData = previousData;
},


handleInputColourEditState(value, index) {
this.colourData.forEach((customer, i) => {
  customer.isEditable = i === index; 
});
this.addNewModelButton=false
this.addNewColorButton=false
this.updateColourData = value;
this.editbutton = true;
this.newModel = true;
this.colour_name = value.colour_name;
this.isActive = value.isActive;

if (value.images && value.images.length > 0) {
  const colourImageNames = value.imageNames.map(imageName => imageName);

  this.formattedColourImageNames = colourImageNames
  this.images = value.images; 
  this.imagesPreview = value.images.map((imageName) => {
    return imageName;
  });
} else {
  this.images = [];
  this.formattedColourImageNames = ''; 
  this.imagesPreview = [];
}



let uppercolourData = [...this.colourData];

uppercolourData[index].isEditable = true;
this.colourData = uppercolourData;


},
async createModel() {
  const BASE_API_URL = process.env.VUE_APP_BASE_APIURL;
      if (!this.model_no) {
          this.$swal("Please enter the Model number!");
          return
      }
      if (!this.selectedColours || this.selectedColours.length === 0) {
          this.$swal("Please select the colour!");
          return
      }

      

    let clinicPayload = {
      model_no: this.model_no,
      colour_id: this.selectedColours,
      admin_customer_id:this.doctorData.customer,
      isActive:this.isActive,
      imageNames:this.imageFileNames,

      images: this.images.filter(Boolean), // Only send non-empty values
    };
    console.log(clinicPayload,"clinicPayload")
    
    await axios
      .post(`${BASE_API_URL}/foot-secure/inventory`, clinicPayload)
      .then((response) => {
          if(response.data){
            this.getInventory() ;
            this.clearDatas();
            this.$swal("Model is saved");
            this.newModel=false
            this.addNewModelButton =true
        }}
      )
      .catch((error) => {
        return error
        });
},
async updateModel(){
  if(this.updateModelData){
  const BASE_API_URL = process.env.VUE_APP_BASE_APIURL;
      if (!this.model_no) {
          this.$swal("Please enter the Model number!");
          return
      }
      if (!this.selectedColours || this.selectedColours.length === 0) {
          this.$swal("Please select the colour!");
          return
      }

    let clinicPayload = {
      model_no: this.model_no,
      colour_id: this.selectedColours,
      admin_customer_id:this.doctorData.customer,
      isActive:this.isActive,
      images: this.images.filter(Boolean),
      imageNames: this.imageNames,
      imageFileNames: this.imageFileNames
    };
    await axios
      .put(`${BASE_API_URL}/foot-secure/inventory/${this.updateModelData.id}/update`, clinicPayload)
      .then((response) => {
          if(response.data){
            this.getInventory() ;
            this.clearDatas();
            this.$swal("Model is updated");
            this.addNewModelButton=true
            this.newModel=false
            this.imagesPreview = this.imagesPreview.filter(Boolean);
        }}
      )
      .catch((error) => {
        return error
        });
      }else{
        this.$swal("Cannot updata");
  }
},


async createColourModel() {
  const BASE_API_URL = process.env.VUE_APP_BASE_APIURL;
      if (!this.colour_name) {
          this.$swal("Please enter the Colour name!");
          return
      }
let clinicPayload = {
      colour_name: this.colour_name,
      admin_customer_id:this.doctorData.customer,
      isActive:this.isActive,
      imageNames:this.imageFileNames,
      images: this.images.filter(Boolean), 

    };

    console.log(clinicPayload,"clinicPayload")
    await axios
      .post(`${BASE_API_URL}/foot-secure/inventory/colour`, clinicPayload)
      .then((response) => {
          if(response.data){
            this.getInventoryColor() ;
            this.clearDatas();
            this.addNewColorButton= true
            this.$swal("Colour is saved");
            this.newModel=false
        }}
      )
      .catch((error) => {
        return error
        });
},
  async updateColourModel(){
  if(this.updateColourData){
  const BASE_API_URL = process.env.VUE_APP_BASE_APIURL;
      if (!this.colour_name) {
          this.$swal("Please enter the colour name!");
          return
      }
   

let clinicPayload = {
  colour_name: this.colour_name,
      admin_customer_id:this.doctorData.customer,
      isActive:this.isActive,
      images: this.images.filter(Boolean),
      imageNames: this.imageFileNames
    };
    await axios
      .put(`${BASE_API_URL}/foot-secure/inventory/colour/${this.updateColourData.id}/update`, clinicPayload)
      .then((response) => {
          if(response.data){
            this.getInventoryColor() ;
            this.clearDatas();
            this.$swal("Colour is updated");
            this.newModel=false
            this.addNewColorButton=true
            this.addNewModelButton =true
            this.imagesPreview = this.imagesPreview.filter(Boolean);
        }}
      )
      .catch((error) => {
        return error
        });
      }else{
        this.$swal("Cannot updata");
      }
},
validMobile(inputtxt){
          const mobilevalidation = /^[6-9]\d{9}$/;
          if(inputtxt.match(mobilevalidation)){
              return true
          }else{
              this.$swal("Please enter the correct mobile number!");
              return false;
          }
      },
  validEmail(inputtxt) {
      var email = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
      var consecutiveSpecialChar = /[\W]{2}/;

      if (inputtxt.match(email) && !consecutiveSpecialChar.test(inputtxt)) {
          return true;
      } else {
          this.$swal("Please enter the correct email ID!");
          return false;
      }
  },
  getInventory() {
const BASE_API_URL = process.env.VUE_APP_BASE_APIURL;
axios
  .get(`${BASE_API_URL}/foot-secure/inventory`)
  .then((response) => {
    this.inventoryData = response.data?.filter(item => item?.admin_customer_id?.id === this.doctorData?.customer?.id);
    
    
    this.inventoryData.forEach(item => {
      if (item.images && item.images.length > 0) {
        item.imagesPreview = item.images.map(imageName => {
          return `${BASE_API_URL}/path/to/images/${imageName}`; 
        });
        item.imageFileNames = item.images.map(imageName => imageName);
        this.formattedImageNames = item.imageNames
      } else {
        item.imagesPreview = [];
        item.imageFileNames = []; 
        this.formattedImageNames = ''; 
      }
    });
  })
  .catch((error) => {
    console.error("Error fetching inventory:", error);
  });
},

getInventoryColor() {
const BASE_API_URL = process.env.VUE_APP_BASE_APIURL;
axios
  .get(`${BASE_API_URL}/foot-secure/inventory/colour`)
  .then((response) => {
    this.colourData = response.data?.filter(item => item?.admin_customer_id?.id === this.doctorData?.customer?.id);
    this.colourDataInventory = response.data?.filter(item => (item?.admin_customer_id?.id === this.doctorData?.customer?.id) && item.isActive ===true);

    this.colourData.forEach(item => {
      if (item.images && item.images.length > 0) {
        item.imagesPreview = item.images.map(imageName => {
          return `${BASE_API_URL}/path/to/images/${imageName}`; 
        });
        item.imageFileNames = item.images.map(imageName => imageName);
        this.formattedColourImageNames = item.imageNames

      } else {
        item.imagesPreview = [];
        item.imageFileNames = []; 
        this.formattedColourImageNames = ''; 
      }
    });
  })
  .catch((error) => {
    console.error("Error fetching inventory:", error);
  });
},
async getDoctors() {
  const BASE_API_URL = process.env.VUE_APP_BASE_APIURL
  await axios
    .get(`${BASE_API_URL}/hcps/${this.DoctorId}/one`)
    .then((response) => {
      this.doctorData = response.data
      this.coPincode = this.doctorData.pincode
    })
},
},
created: async function () {
  let DoctorUserId = localStorage.getItem("id");
        this.DoctorId = JSON.parse(DoctorUserId);
         if(this.DoctorId){
await this.getDoctors();
   } 
await this.getInventory();
await this.getInventoryColor();

},
};
</script>
<style>



.image-upload-container {
  position: relative;
}

.add-icon {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: #007bff;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 24px;
}

.uploaded-images {
  display: flex;
  flex-wrap: wrap;
}

.uploaded-image {
  position: relative;
  margin-right: 10px;
  border: 2px solid #51cfd4;
  margin-top: 10px;
}

.image-thumbnail {
  height: 200px;
  border-radius: 5px;
}

.remove-icon {
  position: absolute;
  top: 0;
  right: 0;
  background: #00979e;
  border: none;
  color: white;
  font-size: 20px;
  cursor: pointer;
}
.carousel-control-next-icon, .carousel-control-prev-icon {
background-color:#00979e;
width: 5rem;
height: 5rem;
}
.img-carousel-public {
width: 300px;
height:300px;
object-fit: contain;
image-rendering: -webkit-optimize-contrast; 
}
.clinicSubmitBtn{
  display: flex;
  justify-content:center;
}
.consultation-select{
/* width: 300px; */
font-size: 20px;
margin-left: auto;
margin-right: auto;
text-align: left;
margin-bottom: 5px;
-webkit-appearance: auto;
appearance:auto;
}
.clinicSubmitBtn{
  display: flex;
  justify-content:center;
}
fieldset.curve-box {
  border: 2px solid #00979e;
  border-radius: 20px;
  padding: 15px;
  margin-top: 1.5rem;
  }
legend.subHeadingText {
padding: 4px 10px;
margin-bottom: 0px;
display: block;
float: none;
width: auto;
font-size: 20px;
}
.allfieldcontent{
color: red;
margin-top: 15px
}
.star {
color: red;
font-size: 20px;
}
.hideOpacity{
opacity: 0;
}
.yes-button,
.no-button {
padding: 10px 20px;
border: none;
border-radius: 4px;
cursor: pointer;
}

.yes-button {
background-color: #38761D;
color: #fff;
}

.no-button {
background-color: #FF9900;
color: #fff;
}
.popup {
position: fixed;
top: 0;
left: 0;
width: 100%;
height: 100%;
background: rgba(0, 0, 0, 0.2) !important;
display: flex;
align-items: center;
justify-content: center;
}
.page-heading-parter{
color: #00979e;
font-size: 28px;
font-weight: bold;
}

.popup-content {
text-align: center;
background: #fff;
width: 400px;
padding: 20px;
border-radius: 8px;
box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}
.cross-icon{
font-size:25px;
color: #cd381c;
cursor: pointer;
float: right;
}
.inventory_pointer{
cursor: pointer;

}
.inventory_pointer.active {
border-bottom: 2px solid #f6802a;
}
.clinicCreateBtn{
color: #00979e !important;
cursor: pointer;
}

button#btn_clinicconsult_add {
border-color: transparent;
}

.disableCreateBtn{
color:  #dddddd !important;
pointer-events: none;
cursor: auto; 
}
.clinicTextAlign input{
text-align: left !important;;
}

/* .receiveConsultation {
color: #34989f;
}

.checkbox-inline {
color: #34989f;
} */
.checkbox-inline.heading {
font-size: 20px;
margin-bottom: 0px;
}

.my-3.subHeading {
/* color: #34989f; */
font-size: 18px;
}

.ms-5.text-color {
/* color: #34989f; */
font-size: 18px;
}
@media screen and (max-width:991px) {
.mobileStyleDoctor {
margin-left: 20px;
margin-right: 20px;
margin-bottom: 6rem;
}
.mypracticeMobile {
margin-left: 20px;
/* margin-bottom: 10px; */
color: #000;
font-weight: 550;
font-size: 21px;
}
}
</style>
